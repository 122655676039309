import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Listing from "../components/Listing";
import { tagHuman } from "../components/Tags";
const Tags = ({ data, location, pageContext }) => {
  let { tag } = pageContext;
  const posts = data.allMarkdownRemark.nodes;
  console.log("posts", posts);
  return (
    <Layout location={location}>
      <SEO title={tagHuman(tag)} />
      <h1>{tagHuman(tag)}</h1>
      {posts.length ? (
        <div className="listings">
          {posts.map((post, i) => (
            <Listing {...{ post }} key={i} />
          ))}
        </div>
      ) : (
        ""
      )}
    </Layout>
  );
};
export default Tags;
export const pageQuery = graphql`
  query ($tag: String) {
    #
    # ALL BASICALLY THE SAME
    #  AS index.js
    #
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "DD MMM YYYY")
          description
          tags
          images {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 250
                placeholder: NONE
                layout: FIXED
              )
            }
          }
        }
      }
    }
  }
`;
